<template>
  <v-container
    id="login"
    class="fill-height justify-center mt-16"
    tag="section"
  >
    <div class="d-flex flex-wrap justify-center mb-2 mt-6" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div
      class="d-flex flex-wrap justify-center mb-12"
      style="width: 100%; font-size: 20px; font-weight: 300;"
    >
      <h4 style="font-weight: 400;">
        Genetica" · FarmacoGenetica
      </h4>
    </div>

    <!-- <detalle-animado color="5DFFBD"></detalle-animado> -->

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="450"
          class="px-5 py-3"
        >
          <div class="text-center">
            <h1 class="display-2 font-weight-bold mb-8" style="color: #1C093A;">
              Iniciar sesion
            </h1>
          </div>
          <!-- <template v-slot:heading>
          </template> -->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(login)">
              <v-card-text class="text-center pa-0">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="email"
                    :label="'Direccion email' + '*'"
                    :error-messages="errors"
                    color="secondary"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pa-0 justify-center">
                <v-btn
                  default
                  rounded
                  color="primary"
                  type="submit"
                  class="font-weight-bold accent--text mr-0 ml-2"
                >
                  Identificarse
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { SDKError, RPCError, ExtensionError } from "magic-sdk";
import { mapMutations } from "vuex";
import store from "@/store";

export default {
  components: {},
  name: "PagesLogin",
  $_veeValidate: {
    validator: "new"
  },

  data: () => ({
    showPassword: false,
    email: ""
  }),
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapMutations({
      setUserData: "auth/SET_STRAPI_USER_DATA"
    }),
    login() {
      let self = this;
      this.$store
        .dispatch("auth/login", {
          email: this.email
        })
        .then(resp => {
          self.setUserData(resp.data);
          self.$router.push({ name: "Samples" });
        })
        .catch(err => {
          if (err instanceof SDKError) {
            console.log(err);
          }
          if (err instanceof RPCError) {
            console.log(err);
          }
          if (err instanceof ExtensionError) {
            console.log(err);
          }
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(store.commit("auth/SET_AUTH_LOADING", false));
  }
};
</script>

<style lang="scss" scoped>
.v-dialog.v-card {
  background: #ff00ff !important;
}
</style>
